var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"spinnerContainer",staticClass:"vld-parent base-spinner"},[_c('section',[_c('a',{on:{"click":_vm.clearBase}},[_c('Clear',[_vm._v("base ")])],1),_vm._v(" "),_vm._l((_vm.baseData),function(base,index){return _c('div',{key:index,staticClass:"uk-margin"},[_c('div',{staticClass:"uk-flex uk-flex-top uk-margin"},[_c('h2',{staticClass:"uk-margin-remove base-display-title"},[_vm._v("\n          "+_vm._s(base.display_name)+" BASES\n        ")]),_vm._v(" "),_c('button',{staticClass:"icon-info"},[_c('InfoIcon',{nativeOn:{"click":function($event){return _vm.toggleBaseModal(base.handle)}}})],1)]),_vm._v(" "),_c('InfoModal',{attrs:{"id":base.handle},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("\n          Our Bases & Mounts\n          ")]},proxy:true},{key:"img",fn:function(){return [_c('div',{staticClass:"uk-background-cover uk-width-1-2@s uk-width-2-3@m",style:({
              'background-image':
                'url(' +
                'https://frankford.b-cdn.net/sd/ui_assets/bases/' +
                base.handle +
                '.webp' +
                ')',
            }),attrs:{"uk-height-viewport":""}})]},proxy:true},{key:"modal-html",fn:function(){return [_c('p',[_vm._v("\n            Properly securing your umbrella is crucial to ensuring years of\n            sun protection and longevity. Frankford offers a base for any\n            environment, including rust resistant galvanized steel plate,\n            powder-coated steel plate, resin and concrete filled, premium\n            alluminum, and several permanent and semi-permanent mounts. Many\n            of our bases are available in multiple finishes.\n          ")])]},proxy:true}],null,true)}),_vm._v(" "),_c('div',{staticClass:"uk-grid-match uk-grid-small uk-child-width-1-2 uk-text-center",attrs:{"uk-grid":""}},_vm._l((base.models),function(model,index2){return _c('div',{key:index2},[_c('div',{staticClass:"base uk-card uk-padding-small",class:{
              selected: _vm.selectedBase && _vm.selectedBase._id === model._id,
            }},[(model.description)?_c('button',{staticClass:"icon-info base-info uk-position-top-left"},[_c('InfoIcon')],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"left tt top uk-dropdown uk-dropdown-bottom-left",attrs:{"uk-dropdown":"animation: uk-animation-slide-top-small; delay-hide: 100; pos: bottom;"}},[_c('div',{staticClass:"dropdown-wrap"},[_vm._v("\n                "+_vm._s(model.display_name)+"\n                "),_c('br'),_vm._v("\n                ---\n                "),_c('br'),_vm._v("\n                "+_vm._s(model.description)+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"uk-card-media-top"},[_c('Bases',{nativeOn:{"click":function($event){!model.mounts || model.mounts.length < 1
                    ? _vm.selectBase(model, _vm.baseFinish, null, _vm.selectedWheels)
                    : _vm.selectBase(model, _vm.baseFinish, _vm.Stem.Stem8ST, false)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("\n                  "+_vm._s(model.model)+"\n                ")]},proxy:true},{key:"img",fn:function(){return [_c('img',{staticClass:"uk-padding-remove",attrs:{"src":model.image
                        ? model.image
                        : 'https://via.placeholder.com/200'}})]},proxy:true}],null,true)})],1),_vm._v(" "),(model.mounts && model.mounts.length > 0)?_c('div',{staticClass:"stems uk-button-group"},[_c('button',{staticClass:"uk-button uk-width-1-1 uk-button-small",class:{
                  selected:
                    _vm.selectedStem === _vm.Stem.Stem8ST &&
                    _vm.selectedBase &&
                    _vm.selectedBase._id === model._id,
                },attrs:{"uk-tooltip":"Table Use"},on:{"click":function($event){return _vm.selectBase(model, _vm.baseFinish, _vm.Stem.Stem8ST, _vm.selectedWheels)}}},[_vm._v("\n                8\" STEM\n              ")]),_vm._v(" "),_c('button',{staticClass:"uk-button uk-width-1-1 uk-button-small",class:{
                  selected:
                    _vm.selectedBase &&
                    _vm.selectedBase._id === model._id &&
                    _vm.selectedStem === _vm.Stem.Stem18ST,
                },attrs:{"uk-tooltip":"Freestanding Use"},on:{"click":function($event){return _vm.selectBase(model, _vm.baseFinish, _vm.Stem.Stem18ST, _vm.selectedWheels)}}},[_vm._v("\n                18\" STEM\n              ")])]):_vm._e(),_vm._v(" "),(model.finishes && model.finishes.length > 0)?_c('div',{attrs:{"hidden":model.finishes.length === 1}},[_c('div',{staticClass:"uk-grid-collapse uk-light uk-child-width-expand@s",attrs:{"uk-grid":""}},_vm._l((model.finishes),function(finish,index3){return _c('div',{key:index3},[_c('Finish',{ref:finish.handle + model._id,refInFor:true,class:{
                      'selected-finish':
                        _vm.selectedBase &&
                        _vm.selectedBase._id === model._id &&
                        _vm.selectedFinish.handle === finish.handle,
                    },attrs:{"uk-tooltip":finish.display_name},nativeOn:{"click":function($event){return _vm.selectBase(model, finish, _vm.baseStem)}},scopedSlots:_vm._u([(finish.handle !== _vm.FinishHandle.GBK)?{key:"img",fn:function(){return [_c('img',{staticClass:"finishimage",attrs:{"src":_vm.getFinishImg(finish.handle)}})]},proxy:true}:null,(finish.handle !== _vm.FinishHandle.GBK)?{key:"default",fn:function(){return undefined},proxy:true}:null],null,true)})],1)}),0)]):_c('div',[_vm._v("No available finishes.")]),_vm._v(" "),(model.attributes && model.attributes.wheels)?_c('div',{staticClass:"stems uk-width-expand uk-button-group"},[_c('button',{staticClass:"uk-button uk-width-1-1 uk-button-small",class:{
                  selected:
                    _vm.selectedBase &&
                    _vm.selectedBase._id === model._id &&
                    _vm.selectedWheels === false,
                },on:{"click":function($event){return _vm.selectBase(model, _vm.baseFinish, _vm.selectedStem, false)}}},[_vm._v("\n                No Wheels\n              ")]),_vm._v(" "),_c('button',{staticClass:"uk-button uk-width-1-1 uk-button-small",class:{
                  selected:
                    _vm.selectedWheels === true &&
                    _vm.selectedBase &&
                    _vm.selectedBase._id === model._id,
                },on:{"click":function($event){return _vm.selectBase(model, _vm.baseFinish, _vm.selectedStem, true)}}},[_vm._v("\n                Wheels\n              ")])]):_vm._e()])])}),0)],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }