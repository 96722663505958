






































































































































































































































import { Component, Prop } from "vue-property-decorator";
import Bases from "@/components/designer/sidebar/base/Base.vue";
import Finish from "@/components/designer/sidebar/frame/Finish.vue";
import { mixins } from "vue-class-component";
import { BaseStore, LayoutStore, ShapeStore } from "@/mixins/store";
import { GetFinishImage } from "@/mixins/helpers";
import { BaseModel } from "@/models/products/base";
import { Finish as FinishModel, FinishHandle } from "@/models/products/finish";
import ProductService from "@/services/product_service";
import UIkit from "uikit";
import { ProductListRes, ProductType } from "@/models/products/product";
import { Collection, ProductLineName } from "@/models/products/collection";
import { APIError, ViewerError } from "@/services/error_service";
import { Stem } from "@/models/configurator/configurator_options";
import { StockMixin } from "@/mixins/stock";

@Component({
  components: {
    Bases,
    Finish,
  },
})
export default class BaseSidebar extends mixins(
  BaseStore,
  LayoutStore,
  GetFinishImage,
  ShapeStore,
  StockMixin
) {
  @Prop({ default: true }) hasVent!: boolean;
  @Prop({ default: true }) hasValance!: boolean;
  protected ProductLineName = ProductLineName;
  protected selectedBase: BaseModel | null = null;
  protected selectedFinish: FinishModel | null = null;
  protected baseData: null | Collection[] = null;
  protected productService = new ProductService();
  protected FinishHandle = FinishHandle;
  protected selectedStem: Stem | null = null;
  protected selectedWheels: boolean | null = null;
  protected Stem = Stem;
  protected filteredBases = [
    // "50C", // excluded due to cheaply designed base; confident this will still be screened out.
    // "BZ-SM", // models needed; need to check with Bill before going to DoDA
    // "BZ2-SM", // models needed; need to check with Bill before going to DoDA
    // "8ST",
    // "18ST",
    // "36G-SQx2", // models needed; does Bill have existing or updated versions (before sending to DoDA)?
    // "XB", // model should have an added concrete pavers that go within the base itself. Squares with concrete-looking finish (ready for DoDA)
    // "XB+C", // model EXISTS, just needs an updated image -> still image or idea of 3-photo GIF (XB, XB w/ Pavers, w/ Cover)
    // "NGU550-E", // should show up the same as the NGU-550.
    // "38SAP-2", // currently being worked on by DoDA - will soon be unhidden.
  ]; // hide these bases

  async mounted() {
    const loader = this.$loading.show({
      isFullPage: false,
      container: this.$refs.spinnerContainer,
    });
    const data = await this.getBases(loader);
    if (!this.collection.specific_mounting) {
      this.baseData = data! as Collection[];
    } else {
      this.baseData = [
        {
          type: ProductType.Base,
          _id: "1",
          display_name: `${this.collection.display_name} bases & mounts`,
          handle: this.collection.handle,
          models: data! as BaseModel[],
        },
      ] as Collection[];
    }
    this.baseBusinessLogic();
    this.readStoreData();
  }

  /** Specific logic to override base display on specific product lines */
  protected baseBusinessLogic(): void {
    if (this.collection.handle === ProductLineName.Catalina) {
      const availableBasesCatalina = {
        handle: "galvanized_steel_plate",
        models: ["20G-SQ", "24G-SQ", "24G", "30G"],
      };
      this.baseData = this.baseData!.map((base) => {
        if (base.handle === availableBasesCatalina.handle) {
          base.models = (base.models as BaseModel[]).filter((b: BaseModel) => {
            if (availableBasesCatalina.models.includes(b.model)) {
              return b;
            }
          }) as BaseModel[];
          return base as Collection;
        } else {
          return base as Collection;
        }
      });
    }
  }

  protected async clearBase(): Promise<void> {
    this.addBase(null);
    this.addBaseFinish(null);
    this.addBaseStem(null);
    this.addBaseOptions(null);
    this.selectedBase = null;
    this.selectedFinish = null;
    this.selectedStem = null;
    this.selectedWheels = false;
    await this.$viewer.ChangeBase(null);
  }

  /**
   * Check the store for preset data. If there is none, set default
   * selected model to the first one in model array
   */
  protected readStoreData(): void {
    this.selectedBase = this.baseModel;
    this.selectedFinish = this.baseFinish;
    this.selectedStem = this.baseStem;
    this.selectedWheels = this.baseOptions?.wheels ? true : false;
  }

  protected setDefaultStem(model: BaseModel): void {
    if (this.selectedBase!.model === Stem.Stem18ST2) {
      this.selectedStem = Stem.Stem18ST2;
    } else if (model.mounts && model.mounts.length > 0) {
      this.addBaseStem(Stem.Stem8ST);
      this.selectedStem = Stem.Stem8ST;
    } else {
      this.addBaseStem(null);
      this.selectedStem = null;
    }
  }

  protected setDefaultFinish(model: BaseModel): void {
    const finish = model.finishes[0] ? model.finishes[0] : null;
    this.selectedFinish = finish;
    this.addBaseFinish(finish);
  }

  protected async getBases(
    loader?: any
  ): Promise<Collection[] | BaseModel[] | undefined> {
    let bases;
    let res: ProductListRes;
    try {
      if (this.collection.specific_mounting) {
        bases = [] as BaseModel[];
        res = await this.productService.getBases(this.umbrellaModel?.model);
        bases = (res.data! as any).bases as BaseModel[];
        // bases = bases.filter(
        //   (model) => !this.filteredBases.includes(model.model)
        // );
      } else {
        bases = [] as Collection[];
        res = await this.productService.getBases();
        console.log("res.data:", res.data!);
        bases = res.data!.filter(
          (pl: any) =>
            pl.type === ProductType.Base
            // pl.type === ProductType.Base &&
            // pl.handle !== "aluminum_shell" && // IRRELEVANT
            // pl.handle !== "specialty" && // unsure why this was screened out
            // pl.handle !== "in_ground_direct_mount" // unsure why this was screened out
        );
        for (const [i, pl] of (bases as Collection[]).entries()) {
          let models: BaseModel[] = [...pl.models] as BaseModel[];
          models = models.filter(
            (model) => !model.is_umbrella_specific
              // && !this.filteredBases.includes(model.model)
          );
          (bases as Collection[])[i].models = models;
        }
      }
      if (loader) {
        loader.hide();
      }
    } catch (err) {
      if (err instanceof APIError) {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return bases;
  }
  async toggleWheels() {
    const wheelsSelected = !this.selectedWheels; // Toggle the current state
    await this.addBaseOptions({ wheels: wheelsSelected }); // Dispatch action to update baseOptions
  }
  protected selectBase(
    model: BaseModel,
    finish: FinishModel | null = null,
    stem: Stem | null = null,
    wheels: boolean | null = null
  ): void {
    this.selectedBase = model;
    if (!stem) {
      this.setDefaultStem(model);
    } else {
      this.selectedStem = stem;
      this.addBaseStem(stem);
    }
    if (!finish || !model.finishes.includes(finish)) {
      this.setDefaultFinish(model);
    } else {
      this.selectedFinish = finish;
      this.addBaseFinish(finish);
    }
    // @ts-ignore
    if (model.attributes && model.attributes.wheels && !wheels) {
      this.selectedWheels = false;
      this.addBaseOptions(null);
      // @ts-ignore
    } else if (model.attributes && model.attributes.wheels && wheels) {
      this.selectedWheels = true;
      this.addBaseOptions({ wheels: true });
    } else {
      this.selectedWheels = null;
      wheels = null;
    }

    this.addBase(model);
    this.sendToViewer(wheels);
  }

  protected async sendToViewer(wheels: boolean | null): Promise<void> {
    let base;
    //Certain base+18ST2 combinations are a separate product with no stem attribute. Detect these bases and set selectedStem to 18ST2 & trim from model name (this is how the viewer accepts this data)
    if (
      this.selectedBase!.model &&
      this.selectedBase!.model !== Stem.Stem18ST2 &&
      this.selectedBase!.model.indexOf("18ST2") > -1
    ) {
      base = this.selectedBase!.model.slice(0, -6);
      this.selectedStem = Stem.Stem18ST2;
    } else if (!this.selectedBase!.model) {
      base = null;
    } else {
      base = this.selectedBase!.model;
    }
    try {
      console.log("this.selectedBase:", this.selectedBase);
      console.log("this.selectedStem:", this.selectedStem);
      console.log("this.selectedFinish:", this.selectedFinish);
      if (this.selectedBase) {
        const stem = this.selectedStem;
        const finish = this.selectedFinish
          ? this.selectedFinish.handle
          : this.selectedBase!.finishes[0].handle;
        const stemFinish = FinishHandle.SS; // Stems 8ST 18ST & 18ST2 all have stainless steel finishes no matter what color the base is in.
        await this.$viewer.ChangeBase(base, finish, stem, stemFinish);
        if (base && typeof wheels === "boolean") {
          await this.$viewer.ToggleBaseWheels(wheels);
        }
      }
    } catch (err) {
      ViewerError.redirect(err);
    }
  }

  protected toggleBaseModal(id: string): void {
    UIkit.modal(document.getElementById(id) as HTMLElement).show();
  }
}
